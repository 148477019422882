<template>
  <div class="plan-card position-relative rounded-5 p-2" :class="{'active': active}">
    <div class="plan-card-title text-center">
      <h3 class="fs-3 lh-1">{{ title }}</h3>
      <p class="fs-6">{{ subtitle }}</p>
    </div>
    <div class="plan-card-description text-center">
      <p class="fs-6" style="max-width: 300px">{{ description }}</p>
    </div>
    <div
      v-if="price[currency]"
      class="plan-card-price text-center mb-3"
      :class="{ 'mb-lg-4': !sucursal[currency] }">
      <span v-show="price[currency]" class="fs-3" style="margin-right: 0.3rem">{{currency}}</span>
      <strong>{{ planPrice }}</strong>
      <small class="fs-5">{{ interval }}</small>
      <p class="fs-6 mt-n1" v-if="sucursal[currency]">
        {{ `+ ${sucursalPrice}${interval} por cada sucursal` }}
      </p>
    </div>
    <Checklist
      class="plan-card-feature fs-5"
      :dark="active"
      :weight="'bold'"
      :check="true"
      :fitList="true"
      :list="features" />
    <Button class="plan-card-cta position-absolute m-auto" :class="{'active': active}"
      style="border-color: transparent" :route="ctaLink">
      {{ ctaText }}
    </Button>
  </div>
</template>

<script>
import Checklist from '@/components/common/Checklist.vue';
import Button from '@/components/common/Button.vue';

export default {
  name: 'PlanCard',
  components: {
    Checklist,
    Button,
  },
  data() {
    return {};
  },
  computed: {
    planPrice() {
      const [price] = this.$priceFormat(this.planAnual ? this.price[this.currency] * 10 : this.price[this.currency]).split('.');
      return price;
    },
    planPeriod() {
      if (this.price[this.currency]) return this.planAnual ? 'Year' : 'Month';
      return '';
    },
    interval() {
      const interval = this.planAnual ? '/año' : '/mes';
      return this.price[this.currency] > 0 ? interval : ''
    },
    sucursalPrice() {
      const [price, dec] = this.$priceFormat(this.planAnual ? this.sucursal[this.currency] * 10 : this.sucursal[this.currency]).split('.');
      return parseInt(dec, 10) > 0 ? `${price}.${dec[0]}` : price;
    },
    ctaText() {
      if (this.cta) return this.cta.text;
      return this.price[this.currency] > 0 ? 'Probar gratis' : 'Contratar';
    },
    ctaLink() {
      if (this.cta) return this.cta.link;
      return `/form/registro?plan=${this.plan}-${this.currency}-${this.planPeriod}`;
    },
  },
  props: {
    cta: Object,
    plan: String,
    title: String,
    price: Object,
    active: Boolean,
    features: Array,
    currency: String,
    sucursal: Object,
    subtitle: String,
    planAnual: Boolean,
    description: String,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.plan-card {
  min-height: 480px;
  max-width: 320px;
  border: 1px solid $primary;
  font-family: $input-btn-font-family;
  &:hover {
    background: $light;
    border-color: $light;
  }
  &.active {
    background: $dark;
    color: $light;
  }
  &.active:hover {
    background: $dark;
  }
  &-feature {
    margin-bottom: 60px;
  }
  &-price {
    font-size: 3.2rem;
  }
  &-cta {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 63px;
    padding-right: 63px;
  }
}

@media (min-width: 992px) {
  .plan-card {
    min-height: 550px;
    max-width: 370px;
  }
}
</style>
