<template>
  <div class="md-form-info text-light">
    <h1 class="fw-bolder">{{ title }}</h1>
    <p>{{ description }}</p>

    <picture class="d-none d-lg-block">
      <source media="(min-width:758px)" :srcset="require('@/assets/images/' + img)" />
      <img :src="require('@/assets/images/mobile/' + img)" alt="" />
    </picture>
  </div>
</template>

<script>
export default {
  name: 'FormInfo',
  data() {
    return {};
  },
  props: {
    img: String,
    title: String,
    description: String,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-form-info {
  max-width: 515px;
}
</style>
