<template>
  <div class="md-solution-card px-2 py-3 text-center">
    <img :src="require('@/assets/images/' + img)" :alt="title" />
    <p class="text-primary fw-bolder mb-0 text-start">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'BenefitCard',
  data() {
    return {};
  },
  props: {
    title: String,
    description: Object,
    img: String,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-solution-card {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  max-width: 240px;
  max-height: 260px;
  min-width: 240px;
  min-height: 260px;
  background: rgba($primary, 0.15);
  border-radius: 20px;
  img {
    width: 140px;
    height: 140px;
  }
}
</style>
