<template>
  <section class="md-testimonials md-testimonials-bg text-center px-4" :id="testimonials.id">
    <h2 class="text-light">{{ testimonials.title }}</h2>
    <CarouselTestimonials class="md-testimonials-carousel" :slides="testimonials.customers" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import CarouselTestimonials from '@/components/common/CarouselTestimonials.vue';

export default {
  name: 'Testimonials',
  components: {
    CarouselTestimonials,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    testimonials() {
      return this.sections.filter((s) => s.id === 'testimonials')[0];
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-testimonials {
  padding-top: 80px;
  padding-bottom: 100px;
  &-bg {
    background: $gradient90;
  }
  &-carousel {
    max-width: 1600px;
  }
}
</style>
