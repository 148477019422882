<template>
  <div class="md-404 wh-100 flex-center bg-dark">
    <div class="md-404-content text-center text-light">
      <p class="md-404-title fw-bold">Oops!</p>
      <p class="md-404-description fs-3">404 - PAGE NOT FOUND :(</p>
      <Button class="md-404-cta mt-4 mx-auto" :href="'/'">Regresar al Inicio</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';

export default {
  name: 'NotFound',
  components: {
    Button,
  },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-404 {
  height: 100vh;
  &-title {
    font-size: 8rem;
  }
  &-cta {
    width: fit-content;
  }
}

@media (min-width: 992px) {
  .md-404 {
    &-title {
      font-size: 14rem;
    }
  }
}
</style>
