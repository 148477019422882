<template>
  <div
    id="carouselCaptions"
    class="carousel slide m-md-auto overflow-hidden"
    data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" :class="{ active: i < 1 }" v-for="(s, i) in slides" :key="i">
        <img :src="require('@/' + s.img)" class="rounded m-auto" :alt="s.title" />
        <div class="carousel-caption">
          <p class="mb-0 fs-5">{{ s.subtitle }}</p>
          <h3 class="fw-bolder fs-3 fs-lg-2">{{ s.title }}</h3>
        </div>
      </div>
    </div>
    <div class="carousel-indicators">
      <button
        type="button"
        class="bg-primary"
        data-bs-target="#carouselCaptions"
        v-for="(s, i) in slides"
        :key="i"
        :class="{ active: i < 1 }"
        :data-bs-slide-to="i"
        :aria-label="s.subtitle"
        :aria-current="i < 1 ? 'true' : ''"></button>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselCaptions"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselCaptions"
      data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  created() {},
  data() {
    return {};
  },
  props: {
    slides: Array,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.carousel {
  max-width: 1500px;
  &-indicators {
    button {
      border-radius: 50%;
    }
  }
  &-inner {
    margin-bottom: 140px;
    overflow: initial;
    img {
      box-shadow: 20px 20px 60px rgba(88, 22, 216, 0.05);
    }
  }
  &-caption {
    bottom: initial;
  }
}

@media (min-width: 768px) {
  .carousel {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .carousel {
    height: 570px;
    &-inner {
      margin-bottom: 100px;
    }
  }
}
</style>
