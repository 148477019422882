<template>
  <div class="md-faq text-md-center m-lg-auto" :id="FAQ.id">
    <div class="md-faq-content bg-dark text-light">
      <h2 class="mb-1">{{ FAQ.title }}</h2>
      <p class="pb-4 m-md-auto">{{ FAQ.description }}</p>
      <Accordion class="md-faq-accordion" :id="'mdFAQAccordion'" :questions="FAQ.list" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Accordion from '@/components/common/Accordion.vue';

export default {
  name: 'FAQ',
  components: {
    Accordion,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    FAQ() {
      return this.sections.filter((s) => s.id === 'FAQ')[0];
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-faq {
  &-content {
    padding: 80px 16px;
  }
}

@media (min-width: 768px) {
  .md-faq {
    &-content {
      padding: 80px 16px;
    }
  }
}
@media (min-width: 992px) {
  .md-faq {
    padding: 120px 0 160px;
    max-width: 1500px;
    width: 85%;
    &-content {
      border-radius: 40px;
    }
  }
}
</style>
