<template>
  <div class="toggle">
    <input type="checkbox" :id="name" @click="$emit('click')" /><label :for="name">Toggle</label>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  created() {},
  data() {
    return {};
  },
  props: {
    name: String,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.toggle {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 96px;
    height: 44px;
    background: #e6e6f5;
    display: block;
    border: 1px solid $primary;
    border-radius: 50px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    background: $primary;
    border-radius: 50px;
    transition: 0.3s;
  }

  // input:checked + label {
  //   background: $primary;
  // }

  input:checked + label:after {
    left: 100%;
    transform: translateX(-100%);
  }

  label:active:after {
    width: 44px;
  }
}
</style>
