import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import FormSignUp from '../views/FormSignUp.vue';
import FormServices from '../views/FormServices.vue';
import FormMultimarca from '../views/FormMultimarca.vue';
import FormCustomSolution from '../views/FormCustomSolution.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: ['/hoteles', '/antros'],
  },
  {
    path: '/form/registro',
    name: 'FormSignUp',
    component: FormSignUp,
    alias: [
      '/form/Freemium/thank-you',
      '/form/Demo-Negocio-en-Crecimiento/thank-you',
      '/form/Demo-Franquicias-y-Cadenas/thank-you',
    ],
  },
  {
    path: '/form/servicios',
    name: 'FormServices',
    component: FormServices,
    alias: ['/form/Servicio-Alta-Menu/thank-you'],
  },
  {
    path: '/form/multimarca',
    name: 'FormMultimarca',
    component: FormMultimarca,
    alias: ['/form/Cotizacion-Multimarca/thank-you'],
  },
  {
    path: '/form/custom-solutions',
    name: 'FormCustomSolution',
    component: FormCustomSolution,
    alias: ['/form/Solucion-a-Medida/thank-you'],
  },
  { path: '*', component: NotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
