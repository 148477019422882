<template>
  <div class="home">
    <Navbar />
    <main>
      <Hero />
      <Benefits />
      <Features />
      <TrialPeriodCta />
      <HowToUse />
      <Testimonials />
      <Pricing />
      <!-- <Services /> -->
      <CustomSolution />
      <!-- <FAQ /> -->
      <Contact />
    </main>
    <Footer />
    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P4Z6V3X"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
// @ is an alias to /src
import TrialPeriodCta from '@/components/TrialPeriodCta.vue';
import CustomSolution from '@/components/CustomSolution.vue';
import Testimonials from '@/components/Testimonials.vue';
import Navbar from '@/components/common/Navbar.vue';
import HowToUse from '@/components/HowToUse.vue';
import Benefits from '@/components/Benefits.vue';
// import Services from '@/components/Services.vue';
import Features from '@/components/Features.vue';
import Pricing from '@/components/Pricing.vue';
import Contact from '@/components/Contact.vue';
import Footer from '@/components/common/Footer.vue';
import Hero from '@/components/Hero.vue';
// import FAQ from '@/components/FAQ.vue';

export default {
  name: 'Home',
  components: {
    TrialPeriodCta,
    CustomSolution,
    Testimonials,
    HowToUse,
    Features,
    // Services,
    Benefits,
    Pricing,
    Contact,
    Footer,
    Navbar,
    Hero,
    // FAQ,
  },
  mounted() {
    localStorage.clear();
    // Change html head title
    // document.title = 'Vue - Home';
  },
};
</script>
