<template>
  <div class="logo">
    <img src="@/assets/logo.webp" alt="logo" />
    <img src="@/assets/mindfood-light.png" alt="mindfood" />
  </div>
</template>

<script>
export default {
  name: 'Logo',
};
</script>

<style lang="scss" scoped>
img[alt~='logo'] {
  margin-right: 6px;
  width: 20px;
}
img[alt~='mindfood'] {
  height: 16px;
}
</style>
