<template>
  <div class="accordion m-auto" :id="id">
    <div
      class="accordion-item border-end-0 border-start-0 border-top-0"
      v-for="(q, i) in questions"
      :key="i">
      <h2 class="accordion-header" :id="'heading' + i">
        <button
          class="accordion-button flex-row-reverse"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse' + i"
          aria-expanded="true"
          :aria-controls="'collapse' + i">
          {{ q.question }}
        </button>
      </h2>
      <div
        :id="'collapse' + i"
        class="accordion-collapse collapse mt-0"
        :aria-labelledby="'heading' + i"
        :data-bs-parent="'#' + id">
        <div class="accordion-body text-start pt-0">
          {{ q.answer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  created() {},
  data() {
    return {};
  },
  props: {
    id: String,
    questions: Array,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.accordion {
  max-width: 650px;
  &-button {
    justify-content: flex-end;
    align-items: baseline;
    gap: 1rem;
    &::after {
      background-size: 1.5rem;
      margin: 0;
    }
    &:not(.collapsed) {
      font-weight: 700;
      box-shadow: none;
    }
  }
}

@media (min-width: 992px) {
  .accordion {
    max-width: 700px;
  }
}
</style>
