<template>
  <section class="md-use px-3 text-md-center" :id="howToUse.id">
    <h2 class="mb-1">{{ howToUse.title }}</h2>
    <p class="mb-4">{{ howToUse.description }}</p>
    <Carousel :slides="howToUse.carousel" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Carousel from '@/components/common/Carousel.vue';

export default {
  name: 'HowToUse',
  components: {
    Carousel,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    howToUse() {
      return this.sections.filter((s) => s.id === 'howToUse')[0];
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-use {
  padding: 100px 0;
}

@media (min-width: 992px) {
  .md-use {
    padding: 140px 0;
  }
}
</style>
