<template>
  <div>
    <div class="navbar-header d-flex justify-content-between align-items-center">
      <a class="navbar-brand" href="/"><Logo /></a>
      <HamburgerButton ref="burgerBtn" :clicked="visible" @click="toggledNav" />
    </div>

    <ul class="navbar-nav my-5 my-lg-0 w-100 justify-content-between">
      <li class="nav-item mb-3 mb-lg-0" v-for="(s, i) in navLinks" :key="i">
        <a class="nav-link text-light" :href="'#' + s.id" @click="toggledNav">{{ s.name }}</a>
      </li>
    </ul>
    <div class="d-lg-flex pt-5 pt-lg-0">
      <Button
        class="d-none d-lg-block px-lg-3 py-lg-1 me-lg-3 fs-6 fw-normal"
        :styled="'outline'"
        :href="'#' + demoLink"
        >Quiero mi demo gratis</Button
      >
      <Button
        class="d-lg-none w-100 mb-2 px-lg-3 py-lg-1 me-lg-4 fs-6 fw-normal"
        :href="'#' + demoLink"
        @click="toggledNav"
        >Quiero mi demo gratis</Button
      >
      <Button
        class="d-none d-lg-block px-lg-3 py-lg-1 fs-6 fw-normal w-100"
        :href="`${urls.admin}/login`"
        target="_blank"
        :styled="'outline'"
        >Iniciar sesión</Button
      >
    </div>
  </div>
</template>

<script>
import HamburgerButton from '@/components/common/HamburgerButton.vue';
import Button from '@/components/common/Button.vue';
import Logo from '@/components/common/Logo.vue';

import { urls } from '../../config';

export default {
  name: 'Nav',
  components: {
    Logo,
    Button,
    HamburgerButton,
  },
  data() {
    return {
      urls,
      closeNav: false,
    };
  },
  props: {
    visible: Boolean,
    navLinks: Array,
    demoLink: String,
  },
  methods: {
    toggledNav() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-brand {
  height: fit-content;
}
.navbar-nav {
  max-width: 700px;
  .btn {
    font-size: 1.4rem;
  }
}

// @media (min-width: 1400px) {
//   .navbar-nav {
//     max-width: 700px;
//   }
// }
</style>
