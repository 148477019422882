import { http, log, handleError } from '../../config';

export default {
  namespaced: true,
  state: { loading: false, error: null, plans: { sucursales: [] } },

  actions: {
    signUp({ commit }, data) {
      log('singUp', data);
      commit('loading');

      const config = {
        method: 'post',
        url: '/auth/signup',
        headers: { 'Content-Type': 'application/json' },
        data,
      };

      return http(config)
        .then(() => commit('requestSuccess'))
        .catch((err) => {
          commit('requestFailure', err);
          if (err.response) throw err.response.data;
          handleError(err);
        });
    },
    resendEmail({ commit }, email) {
      const config = {
        method: 'post',
        url: '/auth/resend-email',
        headers: {
          'Content-Type': 'application/json',
        },
        data: { email },
      };

      return http(config).catch((err) => {
        if (err.response) commit('requestFailure', err.response.body);
        handleError(err);
      });
    },
  },

  mutations: {
    loading(state) {
      state.loading = true;
    },
    requestSuccess(state) {
      state.loading = false;
    },
    requestFailure(state, error) {
      state.loading = false;
      state.error = error;
    },
    // sucursalesPlansSuccess(state, plans) {
    //   state.plans.sucursales = plans;
    // },
  },
};
