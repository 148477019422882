<template>
  <section class="md-pricing" :id="pricing.id">
    <div class="md-pricing-content">
      <div class="md-pricing-header text-center pb-3 px-2">
        <h2>{{ pricing.title }}</h2>
        <p class="mb-4">{{ pricing.description }}</p>
        <div
          class="
            md-pricing-selector
            d-flex
            justify-content-md-center
            d-lg-none
            overflow-auto
            mb-3
            p-1
          ">
          <button
            class="btn rounded-pill border border-1 border-secondary px-2"
            :class="{ 'btn-primary': plan === i }"
            type="button"
            @click="switchPlan(i)"
            v-for="(p, i) in pricing.plans"
            :key="i">
            {{ p.tag }}
          </button>
        </div>
        <div class="md-pricing-period d-flex justify-content-center align-items-center">
          <span>Mensual</span>
          <Toggle class="d-flex mx-2" :name="'planAnual'" @click="planAnual = !planAnual" />
          <span>Anual</span>
        </div>
        <div class="mt-4" style="border-radius: 15px">
          <p class="fw-bolder">Método de pago no requerido</p>
          <p>Inicia tu demo por 15 días sin necesidad de compartir tu información de pago</p>
        </div>
      </div>
      <div class="md-pricing-plans m-auto d-lg-flex justify-content-center pt-3 px-2">
        <!-- Mobile -->
        <PlanCard
          class="md-pricing-card m-auto d-lg-none"
          :planAnual="planAnual"
          :currency="currency"
          v-bind="currentPlan" />
        <!-- Desktop -->
        <PlanCard
          class="md-pricing-card flex-grow-1 d-none d-lg-block"
          v-for="(p, i) in pricing.plans"
          :key="i"
          :planAnual="planAnual"
          :currency="currency"
          v-bind="p" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import PlanCard from '@/components/common/PlanCard.vue';
import Toggle from '@/components/common/Toggle.vue';

export default {
  name: 'Pricing',
  components: {
    Toggle,
    PlanCard,
  },
  data() {
    return {
      planAnual: false,
      plan: 1,  // Preselected plan card in mobile
    };
  },
  computed: {
    ...mapState(['sections']),
    currency() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return this.$mexTimezone.includes(timezone) ? 'MXN' : 'USD';
    },
    pricing() {
      return this.sections.filter((s) => s.id === 'pricing')[0];
    },
    currentPlan() {
      return this.pricing.plans[this.plan];
    },
  },
  props: {},
  methods: {
    switchPlan(plan) {
      this.plan = plan;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-pricing {
  padding-top: 100px;
  &-selector {
    &::-webkit-scrollbar {
      display: none;
    }
    button {
      margin: 0 4px;
    }
  }
  &-plans {
    background: #e6e6f5;
    padding-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .md-pricing {
    padding: 140px 0;
    &-plans {
      background: $background;
      padding-bottom: 20px;
    }
  }
}
@media (min-width: 1200px) {
  .md-pricing {
    &-plans {
      gap: 20px;
    }
  }
}
</style>
