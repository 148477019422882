export default {
  companyName: {
    name: 'company',
    required: true,
    placeholder: 'Nombre de la empresa',
  },
  businessKind: {
    name: 'business_kind',
    value: '',
    type: 'list',
    required: true,
    placeholder: 'Giro del negocio',
    options: [
      'Agencia',
      'Alitas',
      'Antojitos',
      'Antro',
      'Bar',
      'Botanero',
      'Cafeteria',
      'Cervecería',
      'Comida asiática',
      'Comida mexicana',
      'Hotel',
      'Mariscos',
      'Pizzeria',
      'Pub',
      'Restaurante italiano',
      'Otro',
    ],
    child: {
      trigger: 'Otro',
      name: 'business_kind',
      value: '',
      placeholder: 'Dinos tu giro',
    },
  },
  email: {
    name: 'email',
    value: '',
    type: 'email',
    required: true,
    placeholder: 'Correo electrónico',
  },
  password: {
    name: 'password',
    value: '',
    type: 'password',
    required: true,
    placeholder: 'Contraseña',
  },
  firstName: {
    name: 'first_name',
    value: '',
    required: true,
    placeholder: 'Nombre',
  },
  lastName: {
    name: 'last_name',
    value: '',
    required: true,
    placeholder: 'Apellido',
  },
  phone: {
    name: 'phone',
    value: '',
    type: 'tel',
    required: true,
    placeholder: 'Teléfono',
  },
  numSucursales: {
    name: 'numSucursales',
    value: '',
    type: 'list',
    required: true,
    placeholder: 'Cantidad de sucursales o puntos de venta',
    options: [
      '1 sucursal',
      '2-5 sucursales',
      '6-10 sucursales',
      '11-20 sucursales',
      '21-50 sucursales',
      '51-100 sucursales',
      '+100 sucursales',
    ],
  },
  services: {
    name: 'services',
    value: [],
    type: 'multiselect',
    required: true,
    label: 'Selecciona los servicios que te interesan',
    options: [
      'Dar de alta mis productos',
      // 'Que impriman mis códigos QR en estampas para pegar en las mesas',
      'Administración de menú (actualizaciones de descripciones y fotos, cambios de precio, nuevos productos, quitar productos)',
      'Consultoría para reingeniería de menú',
      'Otro',
    ],
  },
  customService: {
    name: 'customService',
    value: '',
    required: true,
    placeholder: '¿Qué servicio te interesa?',
  },
  numProducts: {
    name: 'numProducts',
    value: '',
    type: 'list',
    required: true,
    placeholder: 'Cantidad de productos en el menú',
    options: [
      '1-50 productos',
      '51-100 productos',
      '101-150 productos',
      '151-200 productos',
      '+200 productos',
    ],
  },
  menuResources: {
    name: 'menuResources',
    value: [],
    type: 'multiselect',
    required: true,
    label: 'Selecciona información con la que cuentas',
    options: [
      'Fotografías de mis productos',
      'Descripciones de mis productos',
      'Lista de precios (en caso de tener precios distintos en sucursales)',
    ],
  },
  updateFrequency: {
    name: 'updateFrequency',
    value: '',
    type: 'list',
    required: true,
    placeholder: '¿Cón que frecuencia haces cambios en tu menú?',
    options: ['Diario', 'Semanal', 'Mensual', 'Trimestral', 'Anual'],
  },
  numQR: {
    name: 'numQR',
    value: '',
    type: 'number',
    required: true,
    placeholder: '¿Cuántos códigos QR impresos necesitas?',
  },
  numBrands: {
    name: 'numBrands',
    value: '',
    type: 'number',
    required: true,
    placeholder: 'Cantidad de marcas del grupo',
  },
  website: {
    name: 'website',
    value: '',
    required: true,
    placeholder: 'Pagina Web',
  },
  newSolution: {
    name: 'newSolution',
    value: '',
    required: true,
    type: 'textarea',
    placeholder: 'Cuéntanos un poco más acerca de la solución que necesitas',
  },
  puesto: {
    name: 'puesto',
    value: '',
    required: true,
    placeholder: 'Puesto',
  },
};
