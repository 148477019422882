<template>
  <div class="spinner" :class="{ 'spinner-center': center }">
    <div class="spinner-border" :class="classList" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  data() {
    return {
      classList: {
        'spinner-sm': this.size === 'sm',
        'spinner-lg': this.size === 'lg',
      },
    };
  },
  props: {
    size: String,
    center: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  &-sm {
    width: 2rem;
    height: 2rem;
    border-width: 3px;
  }
  &-lg {
    width: 5rem;
    height: 5rem;
    border-width: 4px;
  }
  &-center {
    @include center;
  }
}
</style>
