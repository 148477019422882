import axios from 'axios';

const devEnv = process.env.NODE_ENV === 'development';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export const urls = {
  admin: process.env.VUE_APP_WEB_ADMIN,
  crmApi: process.env.VUE_APP_API_CRM,
};

export const http = axios;

export const log = (...args) => {
  if (devEnv) {
    console.log(...args);
  }
};

export const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const handleError = (err) => {
  if (err.response) {
    log(err.response.data);
  } else {
    log(err);
  }
};

export const mexTimezone = [
  'America/Bahia_Banderas',
  'America/Cancun',
  'America/Chihuahua',
  'America/Ciudad_Juarez',
  'America/Ensenada',
  'America/Hermosillo',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Merida',
  'America/Mexico_City',
  'America/Monterrey',
  'America/Tijuana',
  'America/Ojinaga',
  'America/Santa_Isabel',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
];

export default {
  axios,
};
