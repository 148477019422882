<template>
  <div class="md-form-inputs bg-light position-relative p-5">
    <div
      class="md-form-inputs-wrapper mb-1 mt-4"
      style="max-height: 500px; overflow-y: auto; overflow-x: hidden">
      <form class="text-dark" :class="{ invisible: loading }" @submit.prevent="$emit('form')">
        <div
          class="md-form-title position-absolute d-flex align-items-center"
          style="left: 10px; top: 20px; z-index: 1">
          <div class="md-form-indicator bg-primary">
            {{ step + 1 }}
          </div>
          <h3 class="text-dark m-0 ms-1">{{ activeStep.title }}</h3>
        </div>
        <div v-if="nextStep" class="md-form-line"></div>
        <div class="md-form-step mb-5">
          <slot name="inputs"></slot>
        </div>
        <Button class="ms-auto mb-3" :type="'submit'">
          {{ nextStep ? 'Siguiente' : 'Finalizar' }}
        </Button>
        <small v-show="error" class="md-form-inputs-error text-danger">{{ errMsg }}</small>
        <div
          class="md-form-title position-absolute d-flex align-items-center"
          style="left: 10px; bottom: 20px">
          <div v-if="nextStep" class="md-form-indicator">
            {{ step + 2 }}
          </div>
          <h4 v-if="nextStep" class="fs-4 m-0 ms-1" style="color: #747474">
            {{ nextStep.title }}
          </h4>
        </div>
      </form>
      <Spinner class="text-primary" v-if="loading" :size="'lg'" :center="true" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import Spinner from '@/components/common/Spinner.vue';

export default {
  name: 'FormInputs',
  components: {
    Spinner,
    Button,
  },
  data() {
    return {};
  },
  props: {
    step: Number,
    error: Boolean,
    errMsg: String,
    loading: Boolean,
    nextStep: Object,
    activeStep: Object,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-form {
  &-inputs {
    // height: fit-content;
    max-width: 500px;
    border-radius: 20px;
  }
  &-indicator {
    width: 24px;
    height: 24px;
    background: #747474;
    color: $light;
    border-radius: 50rem;
    font-weight: 700;
    font-size: 1.4rem;
    @include flex-center;
  }
  &-line {
    position: absolute;
    height: calc(100% - 80px);
    border: 1px solid $primary;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
  }
}
</style>
