<template>
  <div class="md-services p-lg-0" :id="services.id">
    <div class="md-services-content m-auto pt-3 d-lg-flex justify-content-around">
      <div class="md-services-img order-2 m-auto m-lg-0">
        <picture>
          <source
            media="(min-width:992px)"
            :srcset="require('@/assets/images/img-subir-menu.webp')" />
          <img
            :src="require('@/assets/images/mobile/img-subir-menu.webp')"
            alt="Mindfood servicios" />
        </picture>
      </div>
      <div class="md-services-info text-light m-auto m-lg-0 px-1 order-1">
        <h2 class="mb-2 mb-lg-1">{{ services.title }}</h2>
        <p class="text-dark mb-4" v-html="services.description"></p>
        <Button
          class="md-services-info-cta m-auto m-lg-0"
          style="max-width: 280px"
          :styled="'secondary'"
          :href="'/form/servicios'">
          Cotizar
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/common/Button.vue';

export default {
  name: 'Services',
  components: {
    Button,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    services() {
      return this.sections.filter((s) => s.id === 'services')[0];
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-services {
  padding-bottom: 100px;
  background: $gradient90;
  &-content {
    width: 100%;
    img {
      width: 100%;
    }
  }
  &-info {
    max-width: 320px;
  }
}

.icon-circle-check {
  font-size: 10rem;
}

@media (min-width: 768px) {
  .md-services {
    &-content {
      width: 100%;
    }
    &-info {
      max-width: 530px;
    }
    &-img {
      max-width: 530px;
    }
  }
}

@media (min-width: 992px) {
  .md-services {
    &-content {
      align-items: center;
      max-width: 1300px;
    }
    &-info {
      max-width: 500px;
      &-cta {
        width: fit-content;
      }
    }
    &-img {
      max-width: 50%;
    }
  }
}
@media (min-width: 1400px) {
  .md-services {
    &-img {
      max-width: 60%;
    }
  }
}
</style>
