<template>
  <!-- Link button -->
  <a v-if="href" :href="href" :class="classList" :disabled="disabled" @click="$emit('click')">
    <slot></slot>
  </a>
  <!-- Route button -->
  <router-link
    v-else-if="route"
    :to="route"
    :class="classList"
    :disabled="disabled"
    @click="$emit('click')">
    <slot></slot>
  </router-link>
  <!-- App button -->
  <button
    v-else
    :class="classList"
    :type="type || 'button'"
    :disabled="disabled"
    @click="$emit('click')">
    <transition name="fade" mode="out-in">
      <span v-if="!submitted">
        <slot></slot>
      </span>
      <Spinner :size="'sm'" v-else-if="animation === 'loading'" />
    </transition>
  </button>
</template>

<script>
import Spinner from '@/components/common/Spinner.vue';

export default {
  name: 'Button',
  components: {
    Spinner,
  },
  data() {
    return {
      classList: {
        'btn': true,
        'd-block': true,
        'rounded-pill': true,
        'btn-sm': this.size === 'sm',
        'btn-lg': this.size === 'lg',
        'btn-secondary': this.styled === 'secondary',
        'btn-outline': this.styled === 'outline',
      },
    };
  },
  props: {
    type: String,
    size: String,
    href: String,
    route: String,
    styled: String,
    animation: String,
    disabled: Boolean,
    submitted: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.btn {
  letter-spacing: 0.04em;
  padding: 12px 20px;
  background: $primary;
  color: $light;
  &:hover,
  &:active {
    background: $dark;
    color: $primary-light;
  }
  /* Button types */
  &-secondary {
    background: $dark;
    border-color: $dark;
    &:hover {
      border-color: $primary-light;
      color: $primary-light;
    }
  }
  &-outline {
    background: transparent;
    border-color: $light;
    color: $light;
    &:hover {
      border-color: $primary-light;
      color: $primary-light;
    }
  }
  /* Button sizes */
  &-lg {
    padding: 12px 40px;
  }
  &-sm {
    padding: 4px 20px;
  }
}

@media (min-width: 992px) {
  .btn {
    padding: 12px 63px;
    &-lg {
      line-height: 1.4;
      padding: 19px 63px;
    }
    &-sm {
      line-height: 1.6;
      padding: 4px 63px;
    }
  }
}

/* Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
