import axios from 'axios';
import { handleError, urls } from '../../config';

export default {
  namespaced: true,
  state: { loading: false, error: null },

  actions: {
    sendForm({ commit }, data) {
      commit('loading');

      const config = {
        method: 'post',
        url: `${urls.crmApi}/v1/forms`,
        headers: { 'Content-Type': 'application/json' },
        data,
      };

      return axios(config)
        .then(() => commit('requestSuccess'))
        .catch((err) => {
          commit('requestFailure', err);
          if (err.response) throw err.response.data;
          handleError(err);
        });
    },
  },

  mutations: {
    loading(state) {
      state.loading = true;
    },
    requestSuccess(state) {
      state.loading = false;
    },
    requestFailure(state, error) {
      state.loading = false;
      state.error = error;
    },
  },
};
