<template>
  <section class="md-hero md-hero-bg position-relative" :id="hero.id">
    <div class="md-hero-content d-lg-flex pt-4 pt-lg-0">
      <div class="md-hero-description text-light px-3 py-4">
        <strong class="fs-3 fs-lg-2 text-dark">{{ hero.subtitle }}</strong>
        <h1 class="fw-bolder mb-1 mb-lg-2">{{ info.title }}</h1>
        <p class="fs-3 fs-lg-2 mb-4 lh-sm">{{ info.description }}</p>
        <Button
          class="md-hero-cta m-auto m-lg-0"
          :href="'#pricing'"
          :styled="'secondary'"
          :size="'lg'">
          {{ hero.cta }}
        </Button>
      </div>
      <div class="md-hero-img text-center" :class="{ 'md-hero-img-hoteles': target === 'hoteles' }">
        <picture>
          <source media="(min-width:758px)" :srcset="require('@/assets/images/' + info.img)" />
          <img
            :class="{ 'w-90': target !== 'main' }"
            :src="require('@/assets/images/mobile/' + info.img)"
            alt="Mindfood menu mobile" />
        </picture>
      </div>
    </div>
    <img
      class="position-absolute d-none d-xl-block opacity-25"
      src="@/assets/images/burrito.webp"
      alt="burrito" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/common/Button.vue';

export default {
  name: 'Hero',
  components: {
    Button,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    hero() {
      return this.sections.filter((s) => s.id === 'hero')[0];
    },
    target() {
      const [target] = window.location.pathname.replace('/', '').split('/') || 'main';
      return target || 'main';
    },
    info() {
      return this.hero.info[this.target];
    },
  },
};
</script>

<style lang="scss" scoped>
.md-hero {
  padding-top: 60px;
  height: fit-content;
  &-description {
    max-width: 540px;
  }
  &-bg {
    background: $gradient180;
  }
  &-cta {
    width: fit-content;
  }
}

.w-90 {
  width: 90%;
}

img[alt~='burrito'] {
  top: 100px;
  left: -100px;
  filter: blur(2px);
  transform: rotate(15deg);
}

@media (min-width: 768px) {
  .md-hero {
    &-description {
      margin: auto;
    }
    &-img img {
      max-width: 70%;
    }
  }
}

@media (min-width: 992px) {
  .md-hero {
    padding-top: 77px;
    font-family: $input-btn-font-family;
    height: 100vh;
    &-content {
      max-width: 85%;
      margin: auto;
      height: 100%;
      align-items: center;
    }
    &-description {
      max-width: 500px;
      margin: 0;
      margin-top: -50px;
      z-index: 10;
    }
    &-img {
      max-width: 50%;
      position: absolute;
      bottom: 0;
      right: 2%;
      &-hoteles {
        bottom: -30px;
      }
      img {
        max-width: 100%;
      }
    }
    &-bg {
      background: $gradient90;
    }
  }
}
@media (min-width: 1400px) {
  .md-hero {
    &-content {
      max-width: 1300px;
    }
    &-description {
      max-width: 660px;
    }
  }
}
@media (min-width: 1600px) {
  .md-hero {
    &-description {
      margin-top: -150px;
    }
    &-img {
      right: 8%;
      img {
        width: 100%;
      }
    }
  }
}
</style>
