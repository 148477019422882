<template>
  <div
    id="carouselTestimonialsControls"
    class="carousel slide carousel-fade m-lg-auto"
    data-bs-ride="carousel">
    <div class="carousel-inner">
      <div
        class="carousel-item d-lg-flex flex-row-reverse overflow-hidden mt-3 row g-0"
        :class="{ active: i < 1 }"
        v-for="(s, i) in slides"
        :key="i">
        <img class="col-lg-6 p-0" :src="require('@/' + s.img)" :alt="'Menu ' + s.company" />
        <div class="carousel-caption col-lg-6 text-start position-static">
          <h3 class="fs-2 fs-md-1">{{ s.company }}</h3>
          <div class="carousel-customer mb-3 d-flex align-items-center">
            <img
              class="rounded-circle me-1"
              :src="require('@/' + s.pic)"
              :alt="`${s.company} - ${s.name}`" />
            <div class="carousel-customer-info lh-1">
              <h4 class="carousel-customer-name fs-3 fs-md-2">{{ s.name }}</h4>
              <p class="carousel-customer-role">{{ s.role.toUpperCase() }}</p>
            </div>
          </div>
          <div class="carousel-customer-quote mb-4">
            <cite v-html="s.quote"></cite>
          </div>
          <Button
            class="m-auto m-lg-0"
            style="width: fit-content"
            :href="s.menuLink"
            target="_blank"
            >{{ 'Ver menú de ' + s.company }}</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';

export default {
  name: 'CarouselTestimonials',
  components: {
    Button,
  },
  created() {},
  data() {
    return {};
  },
  props: {
    slides: Array,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.carousel {
  &-item {
    border-radius: 2rem;
    background: #dad2f3;
  }
  &-caption {
    padding: 60px 16px;
  }
  &-customer {
    img {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
}

@media (min-width: 768px) {
  .carousel {
    &-caption {
      padding: 60px;
    }
  }
}

@media (min-width: 992px) {
  .carousel {
    &-caption {
      padding: 60px 130px;
    }
    &-customer {
      img {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}
</style>
