import Vue from 'vue';
import Vuex from 'vuex';
import menu from './modules/menu.module';
import forms from './modules/form.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sections: [
      {
        id: 'hero',
        name: 'Hero',
        navLink: false,
        info: {
          main: {
            title: 'Para franquicias y restaurantes en crecimiento',
            description:
              'Gestiona el menú digital de todas tus sucursales de forma rápida desde un solo lugar.',
            img: 'img-main.webp',
          },
          hoteles: {
            title: 'Para 🏢 hoteles con múltiples ubicaciones',
            description:
              'Gestiona el menú digital de todas tus sucursales de forma rápida desde un solo lugar.',
            img: 'img-hoteles.webp',
          },
          antros: {
            title: 'Para 🥂 antros y centros nocturnos con múltiples sucursales',
            description:
              'Gestiona el menú digital de todas tus sucursales de forma rápida desde un solo lugar.',
            img: 'img-antros.webp',
          },
        },
        subtitle: 'Menú digital ',
        cta: 'Quiero mi demo gratis',
      },
      {
        id: 'benefits',
        name: 'Beneficios',
        navLink: true,
        title: 'Tu menú es tu herramienta de marketing más poderosa 💪',
        description:
          'Sácale el máximo provecho con nuestra plataforma y véndeles a tus comensales de forma sutil y efectiva.',
        cards: [
          {
            title: 'Personalízalo con tu marca',
            description: {
              short:
                'Ponle tu logo y escoge los colores de tu menú para que represente a tu marca.',
              full: 'Ponle tu logo y escoge los colores de tu menú para que represente a tu marca y se diferencié de la competencia rápidamente.',
            },
            img: 'assets/images/img-personaliza.webp',
          },
          {
            title: 'Ordena los platillos y bebidas a tu gusto',
            description: {
              short: 'Acomoda tus productos mas rentables al principio para vender más.',
              full: 'Acomoda los platillos y bebidas que más rentabilidad te dejan al principio para que sea lo primero que tus comensales vean y por lo tanto compren 😉.',
            },
            img: 'assets/images/img-ordena.webp',
          },
          {
            title: 'Navegación tipo APP',
            description: {
              short: 'Diseñado como una aplicación con fotografías, precios y descripciones.',
              full: 'Tus comensales agradecerán que tu menú sea tan visual y sencillo de navegar, ya que esta diseñado como si fuera una App con fotografías y descripciones.',
            },
            img: 'assets/images/img-navegacion-app.webp',
          },
          {
            title: 'Ahorra costos en diseño e impresiones',
            description: {
              short: 'Haz cambios en tu menú sin tener que solicitarlos al diseñador.',
              full: 'Ya no tendrás que solicitar a tu diseñador que haga cambios en el diseño de tu menú cada que actualices precios o productos.',
            },
            img: 'assets/images/img-ahorra.webp',
          },
        ],
      },
      {
        id: 'features',
        name: 'Características',
        navLink: true,
        features: [
          {
            title: 'Especializado para franquicias y cadenas 🏢',
            description:
              'Sabemos lo difícil que es gestionar los menús de múltiples sucursales, sobretodo al momento de actualizar precios, productos y promociones de cada sucursal o región, por lo que diseñamos una plataforma que soluciona esto y mucho más:',
            options: {
              title: 'Aplica para los siguientes giros:',
              fw: 'bolder',
              list: [
                '*🌮 Restaurantes',
                '*🍻 Bares',
                '*☕ Cafeterías',
                '*🕺 Antros / centros nocturnos',
                '*🏢 Hoteles',
              ],
            },
            img: 'img-franquicias-restaurantes-multisucursal.webp',
          },
          {
            title: 'Plantillas multisucursal',
            description:
              'En menos de 5 minutos puedes hacer cambios a toda tu red de franquicias o sucursales.',
            options: {
              check: true,
              list: [
                'Asigna listas de precios por producto en cada sucursal o grupo de sucursales',
                'Activa o desactiva ciertos productos dependiendo de la sucursal',
                'Da de alta nuevas sucursales con menús pre-cargados',
                'Gestiona promociones exclusivas para cada región o sucursal',
              ],
            },
            img: 'img-multisucursal.webp',
          },
          {
            title: 'Sección de publicidad de tu negocio',
            description:
              'Configúralo para que cada que tus comensales abran tu menú les aparezca un banner de publicidad de tu negocio. Puedes promocionar:',
            options: {
              check: true,
              list: [
                'Nuevos platillos o recomendaciones de la casa',
                'Eventos de tu negocio',
                'Dinámicas y promociones',
                'Aperturas de nuevas sucursales',
              ],
            },
            tip: ' Puedes personalizarlo para que en cada sucursal aparezca un banner distinto. 😉',
            img: 'img-publicidad-negocio.webp',
          },
          {
            title: 'Panel multimarca',
            description: 'Gestiona los menús digitales de múltiples marcas desde un solo lugar.',
            description2:
              'Podrás asignar distintos roles y permisos a personas de tu organización para que puedan gestionar los menús digitales de grupos de marcas o sucursales distintas, todo centralizado desde un solo lugar.',
            options: {
              title: 'Especialmente diseñado para:',
              check: true,
              list: [
                '<b>Agencias de marketing y/o diseño 🎨</b><br>Que llevan la gestión de fotografía de producto, menú, promociones y/o publicidad de múltiples marcas de restaurantes, bares, cafeterías, hoteles o antros.',
                '<b>Grupos, franquicias y cadenas con múltiples marcas 🎯</b><br>Que buscan centralizar y optimizar las operaciones y publicidad de su red a través de una sola plataforma.',
              ],
            },
            img: 'img-panel-multimarca.webp',
          },
        ],
      },
      {
        id: 'trialPeriod',
        name: 'Periodo de prueba',
        navLink: false,
        title: 'Pruébalo gratis por 15 días',
        description:
          'Empieza ya a reducir costos y tiempo automatizando tu negocio y sacando el máximo provecho de tu menú.',
        cta: 'Quiero mi demo gratis',
      },
      {
        id: 'howToUse',
        name: '¿Cómo usar?',
        navLink: true,
        title: 'Rápido de configurar y fácil de usar',
        description: 'Puedes empezar a utilizarlo en tu negocio el mismo día que te registras.',
        carousel: [
          {
            title: 'Registra tu negocio',
            subtitle: 'Paso 1',
            img: 'assets/images/slide-1.webp',
          },
          {
            title: 'Personaliza tu logotipo y los colores',
            subtitle: 'Paso 2',
            img: 'assets/images/slide-2.webp',
          },
          {
            title: 'Agrega las categorías de tu menú',
            subtitle: 'Paso 3',
            img: 'assets/images/slide-3.webp',
          },
          {
            title: 'Agrega tus productos y promociones',
            subtitle: 'Paso 4',
            img: 'assets/images/slide-4.webp',
          },
          {
            title: 'Imprime tu código  QR y comparte tu menú',
            subtitle: 'Paso 5',
            img: 'assets/images/slide-5.webp',
          },
        ],
      },
      {
        id: 'testimonials',
        name: 'Testimonios',
        navLink: false,
        title: 'Clientes satisfechos',
        customers: [
          {
            name: 'Rodrigo Mora',
            role: 'Director general',
            company: 'La Mezcantina',
            quote:
              '"En la actualidad los precios de los insumos son muy cambiantes, por lo que para nosotros ha sido indispensable poder reaccionar rápido a estos cambios, adecuando los precios de nuestro menú de manera continua y <strong>Mindfood ha sido una herramienta imprescindible para lograrlo de forma efectiva en todas nuestras sucursales</strong>"',
            pic: 'assets/images/mezcantina-logo.webp',
            img: 'assets/images/img-testimonios.webp',
            menuLink: 'https://menu.mindfood.com.mx/la-mezcantina-rio-nilo',
          },
        ],
      },
      {
        id: 'pricing',
        name: 'Precios',
        navLink: true,
        title: 'Elige tu plan',
        description: 'Contamos con 3 planes, selecciona el que se acomode más a tus necesidades',
        plans: [
          // {
          //   tag: 'Gratis',
          //   title: 'Gratis',
          //   subtitle: 'para siempre',
          //   description: 'Ideal para pequeños negocios o que van iniciando',
          //   plan: 'MENU-FREEMIUM-Ever',
          //   price: 0,
          //   features: ['1 sola sucursal', '*Hasta 35 productos', 'Hasta 5 promociones'],
          // },
          {
            tag: '1 Sucursal',
            title: 'Negocio',
            subtitle: 'en crecimiento',
            description: 'Ideal para negocios establecidos con una sucursal',
            plan: 'MENU-GR_BUSINESS',
            price: {
              USD: 15,
              MXN: 299
            },
            sucursal: {},
            features: [
              '*1 sola sucursal',
              'Productos ilimitados',
              'Promociones ilimitadas',
              'Personalízalo con tu marca y colores',
              'Módulo de publicidad de tu negocio',
            ],
          },
          {
            active: true,
            tag: 'Multisucursal',
            title: 'Franquicias',
            subtitle: 'y cadenas',
            description: 'Ideal para negocios con múltiples sucursales',
            plan: 'MENU-FRANCHISES',
            price: {
              USD: 20,
              MXN: 399
            },
            sucursal: {
              USD: 12.5,
              MXN: 250
            },
            features: [
              '*Multisucursales',
              'Productos ilimitados',
              'Promociones ilimitadas',
              'Personalízalo con tu marca y colores',
              'Módulo de publicidad de tu negocio',
              'Plantilla multisucursal',
            ],
          },
          {
            tag: 'Grupos',
            title: 'Grupos multimarca',
            subtitle: 'y agencias',
            description: 'Ideal para empresas que gestionan múltiples marcas y sucursales',
            price: {},
            sucursal: {},
            cta: { link: '/form/multimarca', text: 'Cotizar plan' },
            features: [
              'Multisucursales',
              'Productos ilimitados',
              'Promociones ilimitadas',
              'Personalízalo con tu marca y colores',
              'Módulo de publicidad de tu negocio',
              'Plantilla multisucursal',
              'Panel multimarca',
              'Perfiles y roles de usuario',
            ],
          },
        ],
      },
      // {
      //   id: 'services',
      //   name: 'Servicios',
      //   navLink: true,
      //   title: '¿No tienes tiempo para dar de alta tu menú?',
      //   description:
      //     '<b>No te preocupes, nosotros lo hacemos por ti 🙌</b><br>¡Con gusto levantamos tus requerimientos y te mandamos una cotización para dar de alta todos tus productos!',
      // },
      {
        id: 'FAQ',
        name: 'FAQ',
        navLink: false,
        title: 'Preguntas frecuentes',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        list: [
          {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ess.',
          },
          {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ess.',
          },
          {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            answer:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ess.',
          },
        ],
      },
      {
        id: 'solution',
        name: 'Solución a Medida',
        navLink: false,
        title: '¿Requieres una solución de menú digital a la medida o una marca blanca?',
        description:
          'Contamos con un equipo especializado para ofrecerte una solución que se adapte a las necesidades de tu empresa, ofreciéndote apoyo en las siguientes áreas:',
        features: [
          {
            title: 'Servidores dedicados para tu menú digital',
            img: 'img-servidor.webp',
          },
          {
            title: 'Desarrollo de funcionalidades a la medida',
            img: 'img-funcionalidades-medida.webp',
          },
          {
            title: 'Soporte técnico especializado',
            img: 'img-soporte-tecnico.webp',
          },
          {
            title: 'Business Intelligence (reportes y dashboards)',
            img: 'img-business-intelligence.webp',
          },
        ],
      },
      {
        id: 'contact',
        name: 'Contacto',
        navLink: true,
        title: 'Contáctanos',
        description: 'Estamos para escucharte,  escríbenos si tienes alguna duda.',
        email: 'contacto@mindfood.com.mx',
        form: [
          {
            name: 'name',
            label: 'Nombre',
            type: 'text',
            required: true,
            placeholder: '*Tu nombre',
          },
          {
            name: 'email',
            label: 'Correo',
            type: 'email',
            required: true,
            placeholder: '*Tu correo electrónico',
          },
          {
            name: 'phone',
            label: 'Teléfono',
            maxlength: 12,
            type: 'tel',
            required: true,
            placeholder: '*Número celular',
          },
          {
            name: 'message',
            label: 'Mensaje',
            type: 'textarea',
            required: false,
            placeholder: 'Mensaje',
          },
        ],
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {
    menu,
    forms,
  },
});
