<template>
  <section class="md-benefits m-auto" :id="benefits.id">
    <div class="md-benefits-content px-3">
      <div class="md-benefits-intro mb-4 text-md-center mb-md-4 mb-lg-5 m-md-auto">
        <h2 class="md-benefits-title">{{ benefits.title }}</h2>
        <p class="md-benefits-description pb-lg-3">{{ benefits.description }}</p>
      </div>
      <div class="md-benefits-list d-md-flex flex-wrap">
        <BenefitCard v-for="(b, i) in cards" :key="i" v-bind="b" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import BenefitCard from '@/components/common/BenefitCard.vue';

export default {
  name: 'Benefits',
  components: {
    BenefitCard,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    benefits() {
      return this.sections.filter((s) => s.id === 'benefits')[0];
    },
    cards() {
      const [target] = window.location.pathname.replace('/', '').split('/') || 'main';

      if (target === 'antros')
        return this.benefits.cards.map((c) => {
          if (c.img.includes('img-ordena')) {
            return { ...c, img: 'assets/images/img-bebidas-bares.webp' };
          }
          return { ...c };
        });
      return this.benefits.cards;
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-benefits {
  padding-top: 80px;
  max-width: 1600px;
  &-title {
    max-width: 320px;
  }
}

@media (min-width: 768px) {
  .md-benefits {
    &-intro {
      max-width: 630px;
    }
    &-title {
      max-width: 100%;
    }
    &-list {
      gap: 20px;
    }
    &-card {
      min-width: 300px;
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

@media (min-width: 992px) {
  .md-benefits {
    padding-top: 140px;
    &-intro {
      max-width: 760px;
    }
  }
}
</style>
