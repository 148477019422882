<template>
  <button
    class="navbar-toggler"
    ref="toggler"
    type="button"
    data-bs-toggle="collapse"
    aria-expanded="false"
    aria-label="Toggle navigation"
    @click="$emit('click')">
    <div id="hamburger-icon" :class="{ open: clicked }">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'HamburgerButton',
  props: {
    clicked: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.navbar-toggler {
  border: 0;
}
#hamburger-icon {
  width: 32px;
  height: 19px;
  position: relative;
  margin: 10px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#hamburger-icon span {
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background: $light;
  border-radius: 50px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#hamburger-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon span:nth-child(2) {
  top: 8px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon span:nth-child(3) {
  top: 16px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 0px;
}

#hamburger-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 20px;
  left: 0px;
}
</style>
