/** Pricing formatting */

// Example 1
// let num = 7323452568.283;

// // US system en-US
// var usFormat = num.toLocaleString('en-US');
// console.log(usFormat); // 7,323,452,568.283

// Example 2
// const number = 76346;

// // United state $
// let num = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'USD',
//   minimumFractionDigits: 1,
// });
// console.log(num.format(number)); // $76,346.45

const priceFormat = (price) => {
  const num = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return num.format(price);
};

export default { priceFormat };
