<template>
  <header class="fixed-top" :class="{ 'navbar-100vh': openMenu }">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark py-1 py-lg-3">
      <div class="container-fluid px-3">
        <HamburgerButton :clicked="openNav" @click="toggledNav" />

        <a class="navbar-brand d-lg-none" href="/"><Logo /></a>
        <Button
          class="btn-demo px-2 px-lg-3 py-1 fw-normal d-lg-none"
          :styled="'outline'"
          :href="'#' + pricingLink.id"
          >Demo gratis</Button
        >
        <!-- Desktop Nav -->
        <Nav
          class="collapse navbar-collapse justify-content-between d-none d-lg-block"
          :navLinks="navLinks"
          :demoLink="pricingLink.id" />
      </div>
    </nav>

    <div class="navbar-left-side" :class="{ open: openNav }">
      <!-- Menu left side -->
      <Nav
        class="collapse navbar-collapse show d-lg-none p-2"
        :visible="openNav"
        :navLinks="navLinks"
        :demoLink="pricingLink.id"
        @click="toggledNav" />
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

import HamburgerButton from '@/components/common/HamburgerButton.vue';
import Button from '@/components/common/Button.vue';
import Logo from '@/components/common/Logo.vue';
import Nav from '@/components/common/Nav.vue';

export default {
  name: 'Navbar',
  components: {
    Nav,
    Logo,
    Button,
    HamburgerButton,
  },
  data() {
    return {
      openNav: false,
      openMenu: false,
    };
  },
  computed: {
    ...mapState(['sections']),
    navLinks() {
      return this.sections.filter((s) => s.navLink);
    },
    pricingLink() {
      return this.sections.filter((s) => s.id === 'pricing')[0];
    },
  },
  methods: {
    toggledNav() {
      this.openNav = !this.openNav;

      // Setting 100vh to header
      if (this.openNav) {
        this.openMenu = this.openNav;
      } else {
        setTimeout(() => {
          this.openMenu = this.openNav;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: initial;
  &-100vh {
    height: 100vh;
  }
  &-collapse {
    font-family: $input-btn-font-family;
  }
  &-left-side {
    position: absolute;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50px;
    background: #242424;
    transform: translateX(-100%);
    transition: transform 0.75s;
  }
  &-left-side.open {
    transform: translateX(0);
  }
}
.btn-demo {
  font-size: 1.2rem;
}
.container-fluid {
  max-width: 1600px;
}

@media (min-width: 992px) {
  .navbar-nav .btn {
    font-size: 1.6rem;
  }
}
</style>
