<template>
  <div class="md-benefits-card mb-md-0 mb-3 px-2 py-3 text-center">
    <img :src="require('@/' + img)" alt />
    <h3 class="mb-3">{{ title }}</h3>
    <p class="d-lg-none">{{ description.short }}</p>
    <p class="d-none d-lg-block">{{ description.full }}</p>
  </div>
</template>

<script>
export default {
  name: 'BenefitCard',
  data() {
    return {};
  },
  props: {
    title: String,
    description: Object,
    img: String,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-benefits-card {
  border: 1px solid $primary-light;
  border-radius: 20px;
}
</style>
