<template>
  <div class="md-contact" :id="contact.id">
    <div class="md-contact-content m-auto d-md-flex justify-content-around">
      <div class="md-contact-header text-light mb-4">
        <img class="mb-2" src="@/assets/images/img-contacto.webp" alt="Contacto" />
        <h2 class="mb-2 mb-lg-1">{{ contact.title }}</h2>
        <p class="fs-lg-2">{{ contact.description }}</p>
      </div>
      <div class="md-contact-form position-relative">
        <form
          @submit.prevent="handleForm"
          class="position-relative"
          :class="{ 'opacity-0': submit }">
          <div
            class="form-field position-relative"
            :class="{ focused: focus === i || formData[c.name] }"
            v-for="(c, i) in contact.form"
            :key="i">
            <label :for="c.name" class="form-label position-absolute">{{ c.placeholder }}</label>
            <VuePhoneNumberInput
              v-if="c.type === 'tel'"
              class="form-control border-top-0 border-start-0 border-end-0"
              :default-country-code="'MX'"
              :size="'lg'"
              :color="'#5232CD'"
              :valid-color="'#5232CD'"
              :translations="{
                countrySelectorLabel: 'Código país',
                countrySelectorError: 'Elige un país',
                phoneNumberLabel: c.placeholder,
              }"
              :show-code-on-list="true"
              :no-example="true"
              :only-countries="latamCountries"
              v-model="formData[c.name]"
              @update="formatValue"
              @input="handleInput(formData[c.name], i)" />
            <input
              v-else-if="c.type !== 'textarea'"
              :type="c.type"
              class="form-control border-top-0 border-start-0 border-end-0"
              autocomplete="off"
              v-model="formData[c.name]"
              @input="handleInput(formData[c.name], i)"
              @focus="focus = i"
              :id="c.name" />
            <textarea
              v-else
              :type="c.type"
              class="form-control pb-1 bt-2 border-top-0 border-start-0 border-end-0"
              rows="5"
              v-model="formData[c.name]"
              @focus="focus = i"
              :id="c.name" />
            <small
              class="form-error fs-6 position-absolute"
              v-if="error && c.required && !formData[c.name]"
              >Campo obligatorio</small
            >
            <small
              class="form-error fs-6 position-absolute"
              v-if="error && c.name === 'email' && !validEmail && formData[c.name]"
              >Formato de correo incorrecto</small
            >
            <small
              class="form-error fs-6 position-absolute"
              v-if="error && c.name === 'phone' && !validPhone && formData[c.name]"
              >Deben de ser 10 dígitos</small
            >
          </div>
          <Button class="mt-5" :styled="'secondary'" :type="'submit'">Enviar</Button>
        </form>
        <!-- prettier-ignore -->
        <div
          class="
            form-success
            position-absolute
            top-50
            start-50
            translate-middle
            w-100
            text-center text-light
          "
          :class="{ 'opacity-0': !submit, 'opacity-100': submit }">
          <span class="icon-circle-check text-light"></span>
          <p class="fs-1 fw-bolder">¡Super!</p>
          <p>Se ha enviado tu respuesta, pronto te contactaremos</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import Button from '@/components/common/Button.vue';

export default {
  name: 'Contact',
  components: {
    VuePhoneNumberInput,
    Button,
  },
  data() {
    return {
      focus: null,
      formData: {},
      error: false,
      submit: false,
      latamCountries: [
        'CR',
        'BZ',
        'SV',
        'GT',
        'HN',
        'MX',
        'NI',
        'PA',
        'AR',
        'BO',
        'BR',
        'CL',
        'CO',
        'EC',
        'GY',
        'PY',
        'PE',
        'PR',
        'SR',
        'UY',
        'VE',
      ],
      telPrefix: '52',
    };
  },
  computed: {
    ...mapState(['sections']),
    contact() {
      return this.sections.filter((s) => s.id === 'contact')[0];
    },
    phone() {
      return this.formData.phone;
    },
    validEmail() {
      const reg = /\S+@\S+\.\S+/; // regex mail format
      return reg.test(String(this.formData.email).toLowerCase());
    },
    validPhone() {
      const phone = (this.formData.phone || '').replace(/\D/g, '');

      if (phone) {
        return phone.length === 10;
      }
      return false;
    },
  },
  props: {},
  methods: {
    ...mapActions('forms', ['sendForm']),
    handleForm() {
      this.error = !this.validEmail || !this.validPhone;
      this.validated = true;

      if (!this.error) {
        const data = this.getValues();
        this.$log('Saving form', data);
        this.sendForm({ title: 'Mindfood Contacto', form: data })
          .then(() => {
            this.formData = {};
            this.submit = true;
            this.error = false;
          })
          .catch((error) => this.$log(error));
      }
    },
    getValues() {
      const data = this.contact.form.map((f) => {
        const format = {
          question: f.label,
          answer: this.formData[f.name],
        };

        if (f.name === 'phone') format.answer = `+${this.telPrefix} ${this.formData[f.name]}`;

        return format;
      });

      return data;
    },
    handleInput(value, i) {
      this.formData[this.contact.form[i].name] =
        this.contact.form[i].name === 'phone' ? this.formatPhone(value) : value;
    },
    formatPhone(phone) {
      const cleaned = phone.replace(/\D/g, '');

      if (cleaned.length > 6) {
        return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6, 10)}`;
      }
      if (cleaned.length > 3) {
        return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)}`;
      }
      return cleaned;
    },
    formatValue(data) {
      this.country = this.countries[data.countryCode];
      this.telPrefix = data.countryCallingCode;
      // if (this.type === 'tel') this.content = this.parsePhone(this.content);
      this.content = data.phoneNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-contact {
  padding: 100px 20px;
  background: $gradient180;
  &-content {
    width: fit-content;
  }
  .form-field {
    margin-bottom: 2.4rem;
  }
  &-header {
    max-width: 300px;
  }
  form {
    z-index: 10;
    max-width: 300px;
    button {
      width: 100%;
    }
  }
  .form-error {
    color: #f076c0;
  }
}

.icon-circle-check {
  font-size: 10rem;
}

@media (min-width: 768px) {
  .md-contact {
    &-content {
      width: 100%;
    }
    form {
      min-width: 300px;
    }
  }
}

@media (min-width: 992px) {
  .md-contact {
    padding: 180px 20px 100px;
    background: $gradient90;
    &-content {
      max-width: 1300px;
    }
    &-header {
      max-width: 500px;
    }
    form {
      min-width: 400px;
      max-width: 400px;
      button {
        width: initial;
        margin-left: auto;
      }
    }
  }
}

.form-label {
  left: 0;
  top: 10px;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}
.focused .form-label {
  transform: translateY(-90%);
  font-size: 0.75em;
}
.form-control {
  position: relative;
}
</style>
