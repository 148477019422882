<template>
  <div>
    <label :for="name" class="form-label position-relative mb-0"
      ><span v-if="label" class="d-block mb-1">{{ label }}</span>
      <!-- Select Input -->
      <div v-if="type === 'list'">
        <select
          :class="classList"
          :id="name"
          :name="name"
          v-model="content"
          autocomplete="off"
          style="height: 75px; white-space: pre-wrap"
          @input="handleInput">
          <option value="" disabled selected>{{ placeholder }}</option>
          <option v-for="({ id, name }, i) in optionList" :key="i" :value="id">
            {{ name }}
          </option>
        </select>
        <input
          v-if="child"
          v-show="content === child.trigger"
          class="form-control mt-2"
          :class="{ 'form-error': !child.value }"
          type="text"
          v-model="child.value"
          autocomplete="off"
          :placeholder="child.placeholder"
          @change="
            $emit('valid', {
              valid: content === child.trigger && !!child.value,
              i: $vnode.key,
              name: name,
            })
          " />
        <div
          v-if="child && !child.value && content === child.trigger && validated"
          class="fs-6 text-danger">
          Campo obligatorio
        </div>
      </div>
      <!-- Multiselect -->
      <div
        v-else-if="type == 'multiselect'"
        class="d-flex flex-column justify-content-between fs-5 w-100">
        <div
          v-for="(opt, i) in options"
          :key="i"
          class="form-check p-1 d-flex justify-content-between align-items-center mb-1 position-relative"
          :class="{ 'form-check-selected': content.includes(opt) }">
          <span class="d-flex flex-fill align-items-center process__input__texto">
            {{ opt }}
          </span>
          <span
            class="icon-circle-check fs-2"
            :style="content.includes(opt) ? 'color: #FFF' : 'color: #747474'"></span>

          <input
            class="position-absolute wh-100 opacity-0 top-0"
            style="cursor: pointer"
            type="checkbox"
            :value="opt"
            @change="handleInput" />
        </div>
        <input
          v-if="child"
          v-show="content.includes(child.trigger)"
          class="form-control mt-1"
          :class="{ 'form-error': !child.value }"
          type="text"
          v-model="child.value"
          autocomplete="off"
          :placeholder="child.placeholder"
          @change="handleInput" />
        <div
          v-if="child && !child.value && content === child.trigger && validated"
          class="fs-6 text-danger">
          Campo obligatorio
        </div>
      </div>
      <!-- Textarea field -->
      <textarea
        v-else-if="type === 'textarea'"
        :class="classList"
        :id="name"
        :name="name"
        v-model="content"
        :placeholder="placeholder"
        @input="handleInput"
        rows="5"></textarea>
      <VuePhoneNumberInput
        v-else-if="type === 'tel'"
        class="form-control p-0"
        :default-country-code="'MX'"
        :size="'lg'"
        :error="validated && error"
        :color="'#5232CD'"
        :valid-color="'#5232CD'"
        :error-color="'#F84031'"
        :translations="{
          countrySelectorLabel: 'Código país',
          countrySelectorError: 'Elige un país',
          phoneNumberLabel: placeholder,
        }"
        :border-radius="12"
        :show-code-on-list="true"
        :no-example="true"
        :only-countries="latamCountries"
        v-model="content"
        @update="formatValue"
        @input="handleInput" />
      <!-- Normal Input -->
      <input
        v-else
        :type="type"
        :class="classList"
        :id="name"
        :name="name"
        v-model="content"
        autocomplete="off"
        :maxlength="maxlength"
        :placeholder="placeholder"
        @input="handleInput"
        @change="$emit('change')" />
      <span class="position-absolute dollar" v-if="name === 'price'">$</span>
    </label>
    <div v-if="validated && error && showErr" class="fs-6 text-danger">{{ errorMessage }}</div>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'Input',
  components: {
    VuePhoneNumberInput,
  },
  mounted() {
    if (this.required) {
      this.$emit('valid', { valid: false, i: this.$vnode.key });
    }
    this.validate();
  },
  data() {
    return {
      maxlength: this.type === 'tel' ? 10 : null,
      errorMessage: 'Campo obligatorio',
      content: this.value,
      error: false,
      country: null,
      latamCountries: [
        'CR',
        'BZ',
        'SV',
        'GT',
        'HN',
        'MX',
        'NI',
        'PA',
        'AR',
        'BO',
        'BR',
        'CL',
        'CO',
        'EC',
        'GY',
        'PY',
        'PE',
        'PR',
        'SR',
        'UY',
        'VE',
      ],
      countries: {
        CR: 'Costa Rica',
        BZ: 'Belize',
        SV: 'El Salvador',
        GT: 'Guatemala',
        HN: 'Honduras',
        MX: 'México',
        NI: 'Nicaragua',
        PA: 'Panamá',
        AR: 'Argentina',
        BO: 'Bolivia',
        BR: 'Brasil',
        CL: 'Chile',
        CO: 'Colombia',
        EC: 'Ecuador',
        GY: 'Guyana',
        PY: 'Paraguay',
        PE: 'Perú',
        PR: 'Puerto Rico',
        SR: 'Suriname',
        UY: 'Uruguay',
        VE: 'Venezuela',
      },
      telPrefix: '52',
    };
  },
  props: {
    name: String,
    size: String,
    type: { type: String, default: 'text' },
    label: String,
    showErr: { type: Boolean, default: true },
    child: Object,
    options: Array,
    required: Boolean,
    validated: Boolean,
    placeholder: String,
    validation: Boolean,
    message: String,
    value: [String, Number, Array],
  },
  computed: {
    classList() {
      return {
        'form-control-sm': this.size === 'sm',
        'form-control': this.type !== 'list',
        'form-select': this.type === 'list',
        'form-error': this.validated && this.error,
        'ps-5': this.name === 'price',
      };
    },
    optionList() {
      if (this.options && typeof this.options[0] === 'string') {
        return this.options.map((v) => ({ id: v, name: v }));
      }

      return this.options;
    },
  },
  watch: {
    content() {
      this.validate();
    },
    value(v) {
      if (this.type !== 'tel') {
        //   this.content = this.parsePhone(v);
        // } else {
        this.content = v;
      }
    },
    validated() {
      this.validate();
    },
    required() {
      this.validate();
    },
  },
  methods: {
    handleInput(e) {
      if (this.type === 'multiselect') {
        if (this.content.includes(e.target.value)) {
          const ind = this.content.indexOf(e.target.value);
          this.content.splice(ind, 1);
        } else {
          this.content.push(e.target.value);
        }
        this.$emit('input', { value: this.content, i: this.$vnode.key, name: this.name });
      } else if (this.$vnode.key >= 0 || this.$vnode.key) {
        const value = this.type === 'tel' ? e.replace(/ /g, '') : e.target.value;
        this.$emit('input', {
          value,
          i: this.$vnode.key,
          name: this.name,
          telPrefix: this.telPrefix,
          country: this.country,
        });
      } else {
        this.$emit('input', e.target.value);
      }
    },
    formatValue(data) {
      this.country = this.countries[data.countryCode];
      this.telPrefix = data.countryCallingCode;
      // if (this.type === 'tel') this.content = this.parsePhone(this.content);
      this.content = data.phoneNumber;
    },
    isEmail(value) {
      return this.$regex.email.test(value);
    },
    parsePhone(value) {
      const phone = value.replace(/\D/g, '');

      if (phone.length > 6) {
        return `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6, 10)}`;
      }
      if (phone.length > 3) {
        return `${phone.slice(0, 3)} ${phone.slice(3, 6)}`;
      }

      return phone;
    },
    validate() {
      const isEmpty = this.required && !this.content;
      const wrongEmail = this.type === 'email' && !this.isEmail(this.content) && this.content;
      const passTooShort = this.type === 'password' && this.content.length < 8 && this.content;
      const incompletePhone =
        this.type === 'tel' &&
        this.content.replace(/[ ()-]/g, '').length !== this.maxlength &&
        !!this.content;
      const missingOptions = this.type === 'multiselect' && this.required && !this.content.length;

      this.error =
        isEmpty ||
        wrongEmail ||
        passTooShort ||
        incompletePhone ||
        this.validation ||
        missingOptions;

      if (wrongEmail) this.errorMessage = 'El formato de correo no es valido';
      if (passTooShort) this.errorMessage = 'Tu contraseña debe ser mínimo 8 caracteres';
      if (incompletePhone) this.errorMessage = 'El teléfono deben ser 10 dígitos';
      if (missingOptions) this.errorMessage = `Selecciona al menos una opción`;
      if (this.validation) this.errorMessage = this.message; // Custom Validation

      this.$emit('valid', { valid: !this.error, i: this.$vnode.key, name: this.name });

      if (
        this.type === 'list' &&
        this.child &&
        !this.child.value &&
        this.content === this.child.trigger
      ) {
        this.$emit('valid', { valid: false, i: this.$vnode.key, name: this.name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label,
input,
textarea,
select {
  width: 100%;
}
.form {
  &-label {
    color: $dark;
  }
  &-control,
  &-select {
    border: 1px solid $dark;
    padding: 10px 15px;
    border-radius: 1.2rem;
    color: $dark;
    &::placeholder {
      color: rgba($dark, 0.5);
    }
    &:focus {
      border: 2px solid $primary;
    }
    &-sm {
      & + .dollar {
        bottom: 7px;
        left: 12px;
      }
    }
  }
  &-check {
    background: rgba($primary-light, 0.3);
    border-radius: 1.2rem;
    &-selected {
      background: $primary-light;
      color: $light;
    }
  }
  &-select {
    padding-right: 45px;
    background-color: transparent;
    background-image: url('../../assets/icons/select-arrow.png');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 24px 24px;
  }
  &-error {
    border: 1px solid $danger;
  }
}
.dollar {
  color: #6c757d;
  bottom: 11px;
  left: 14px;
}
</style>
