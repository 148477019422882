<template>
  <footer class="md-footer bg-dark text-light py-5 px-3 text-center">
    <div class="md-footer-content m-auto">
      <div class="md-footer-links border-bottom mt-5 pb-3 d-md-flex justify-content-between">
        <Logo />
        <div class="md-footer-social mt-5 mt-md-0">
          <ul class="d-flex justify-content-center">
            <!-- <li class="mx-3">
              <a class="p-1 text-light text-decoration-none" href="#"
                ><i class="icon-twitter"></i
              ></a>
            </li> -->
            <li class="mx-3">
              <a class="p-1 text-light text-decoration-none" href="#"
                ><i class="icon-facebook"></i
              ></a>
            </li>
            <li class="mx-3">
              <a class="p-1 text-light text-decoration-none" href="#"
                ><i class="icon-instagram"></i
              ></a>
            </li>
            <li class="mx-3">
              <a class="p-1 text-light text-decoration-none" href="https://www.youtube.com/channel/UC4L0vJ1Q8RLf8MaQUBM5zzQ"
                ><i class="icon-youtube"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="md-footer-info mt-4 fs-6 d-md-flex justify-content-center">
        <p class="mx-md-1">&copy; Mindfood {{ year }}</p>
        <span class="mx-md-1 d-none d-md-block">|</span>
        <p class="mx-md-1">Todos los derechos reservados</p>
        <span class="mx-md-1 d-none d-md-block">|</span>
        <a class="mx-md-1 text-light" href="#">Aviso de privacidad</a>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/common/Logo.vue';

export default {
  name: 'Footer',
  components: {
    Logo,
  },
  data() {
    return {};
  },
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-footer {
  &-content {
    max-width: 1300px;
  }
  &-social {
    ul {
      list-style: none;
      padding: 0;
    }
  }
}
</style>
