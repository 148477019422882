<template>
  <section class="md-solution m-auto" :id="solution.id">
    <div
      class="md-solution-content d-lg-flex px-3 p-lg-0 align-items-center justify-content-between">
      <div class="md-solution-intro mb-5 mb-md-5 w-lg-50 pe-lg-5 m-md-auto m-lg-0">
        <h2 class="md-solution-title">{{ solution.title }}</h2>
        <p class="md-solution-description mb-lg-4">{{ solution.description }}</p>
        <Button class="d-none d-lg-block" style="max-width: 240px" :href="'/form/custom-solutions'">
          Cotizar
        </Button>
      </div>
      <div class="md-solution-list px-5 p-lg-0 m-md-auto m-lg-0">
        <SolutionCard
          class="m-auto mb-3 mb-lg-0"
          :class="'md-solution-card' + i"
          v-for="(b, i) in solution.features"
          :key="i"
          v-bind="b" />
      </div>
      <Button
        class="mx-auto mt-5 d-lg-none"
        style="max-width: 240px"
        :href="'/form/custom-solutions'">
        Cotizar
      </Button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import SolutionCard from '@/components/common/SolutionCard.vue';
import Button from '@/components/common/Button.vue';

export default {
  name: 'CustomSolution',
  components: {
    SolutionCard,
    Button,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    solution() {
      return this.sections.filter((s) => s.id === 'solution')[0];
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-solution {
  padding: 100px 0;
  max-width: 1600px;
  &-title {
    max-width: 320px;
  }
}

@media (min-width: 768px) {
  .md-solution {
    &-intro {
      max-width: 630px;
    }
    &-title {
      max-width: 100%;
    }
    &-card {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

@media (min-width: 768px) {
  .md-solution {
    &-intro {
      max-width: 630px;
    }
    &-list {
      width: 580px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 45px);
      row-gap: 11px;
      column-gap: 20px;
    }
    &-card0 {
      grid-column: 1;
      grid-row: 1;
    }
    &-card1 {
      grid-column: 2;
      grid-row: 2;
    }
    &-card2 {
      grid-column: 1;
      grid-row: 6;
    }
    &-card3 {
      grid-column: 2;
      grid-row: 7;
    }
  }
}
@media (min-width: 992px) {
  .md-solution {
    padding: 200px 0 100px;
    max-width: 1000px;
    &-intro {
      max-width: 630px;
    }
    &-list {
      width: 500px;
    }
  }
}
@media (min-width: 1400px) {
  .md-solution {
    max-width: 1200px;
  }
}
</style>
