<template>
  <div class="md-form bg-dark">
    <!-- Header -->
    <nav class="navbar navbar-dark bg-dark py-1 py-lg-3">
      <div class="container">
        <a class="navbar-brand" href="/"><Logo /></a>
      </div>
    </nav>
    <!-- Content -->
    <div class="md-form-wrapper container justify-content-lg-between row px-3 mx-0 py-5 m-md-auto">
      <FormInfo class="col-12 col-lg-6 mb-5" v-bind="info" />
      <FormInputs
        v-if="!success || error"
        class="col-12 col-lg-6"
        :step="step"
        :error="error"
        :errMsg="errorMessage"
        :loading="loading"
        :nextStep="nextStep"
        :activeStep="activeStep"
        @form="handleForm">
        <template v-slot:inputs>
          <Input
            class="mb-2"
            v-for="input in activeStep.inputs"
            :key="input.name"
            v-bind="input"
            :validated="validated"
            @valid="checkInput"
            @input="handleInput" />
        </template>
      </FormInputs>
      <FormSuccess
        v-else
        class="col-12 col-lg-6"
        :title="finish.title"
        :description="finish.description(email)">
        <template v-slot:icon>
          <img style="width: 140px; height: 140px" src="@/assets/icons/mail-unread.png" alt="" />
        </template>
        <template v-slot:extra>
          <p v-if="finish.extra" class="fs-4 mb-5">
            {{ finish.extra }}
            <a
              style="color: #5232cd; text-decoration: underline; cursor: pointer"
              @click="resendEmail(email)"
              >click aquí</a
            >.
          </p>
          <Button class="mx-auto" style="width: fit-content" :href="finish.href">
            Iniciar Sesión
          </Button>
          <router-link
            class="mx-auto mt-3 d-block text-decoration-none"
            style="width: fit-content"
            to="/">
            Volver al inicio
          </router-link>
        </template>
      </FormSuccess>
    </div>
    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P4Z6V3X"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Logo from '@/components/common/Logo.vue';
import Input from '@/components/common/Input.vue';
import Button from '@/components/common/Button.vue';
import FormInfo from '@/components/form/FormInfo.vue';
import FormInputs from '@/components/form/FormInputs.vue';
import FormSuccess from '@/components/form/FormSuccess.vue';

import input from '@/store/inputs';
import { urls } from '../config';

export default {
  name: 'Form',
  components: {
    FormSuccess,
    FormInputs,
    FormInfo,
    Button,
    Input,
    Logo,
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const planCode = urlParams.get('plan');

    if (planCode) this.planCode = planCode;

    if (planCode && planCode.includes('FRANCHISES')) {
      this.form[0].inputs.splice(2, 0, {
        name: 'numSuc',
        value: '',
        type: 'number',
        required: true,
        placeholder: '¿Cuántas sucursales necesitas?',
      });
    }

    const email = localStorage.getItem('email');
    this.success = !!email;
  },
  data() {
    return {
      step: 0,
      error: null,
      errorMessage: 'Algo ha salido mal, intenta de nuevo',
      success: false,
      planCode: null,
      validated: false,
      info: {
        title: 'Estas a un paso de crear tu menú👌',
        description:
          'Déjanos tus datos. Contamos con un equipo especializado para ofrecerte una solución que se adapte a tus necesidades.',
        img: 'img-form-menu.webp',
      },
      form: [
        {
          title: 'Crea tu cuenta',
          inputs: [
            input.companyName,
            input.businessKind,
            { ...input.email, name: 'email' },
            input.password,
            {
              ...input.password,
              name: 'passwordMatch',
              placeholder: 'Confirmar contraseña',
              message: 'La contraseña no coincide',
            },
          ],
        },
        {
          title: 'Información general',
          inputs: [input.firstName, input.lastName, input.phone],
        },
      ],

      finish: {
        title: '¡Super, ya estás registrado!',
        description(email) {
          return `Para comenzar a usar tu cuenta de Mindfood necesitas verificar tu cuenta de correo <b>${email}</b>, al cual hemos enviado un correo para la verificación.`;
        },
        extra: 'Si no recibiste el correo de verificación has ',
        href: `${urls.admin}/login`,
      },
    };
  },
  computed: {
    ...mapState({ menu: (state) => state.menu }),
    loading() {
      return this.menu.loading;
    },
    activeStep() {
      return this.form[this.step];
    },
    nextStep() {
      return this.form[this.step + 1];
    },
    description() {
      return this.info.finish.description(this.email);
    },
    email() {
      const index = this.form[0].inputs.length === 5 ? 2 : 3;
      return this.form[0].inputs[index].value;
    },
    isPasswordMatch() {
      const index = this.form[0].inputs.length === 5 ? 3 : 4;
      return this.form[0].inputs[index].value === this.form[0].inputs[index + 1].value;
    },
    currency() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return this.$mexTimezone.includes(timezone) ? 'MXN' : 'USD';
    },
  },
  props: {},
  methods: {
    ...mapActions('menu', ['signUp', 'resendEmail']),
    handleForm() {
      const stepErr = this.form[this.step].inputs.find((i) => !i.valid);
      this.validated = true;
      this.error = false;

      this.$log(this.form);
      if (!stepErr) {
        if (this.step < this.form.length - 1) {
          this.validated = false;
          this.step += 1;
        } else {
          this.$log('Saving form');
          const data = this.getValues();
          const numSucursales = data.numSuc;

          data.plan = { code: this.planCode };

          // const [interval] = data.plan_code.split('-').reverse();

          if (numSucursales) {
            // const { code } = this.menu.plans.sucursales.find(
            //   (p) => p.code.includes(numSucursales) && p.code.includes(interval),
            // );

            // if (code) data.complement_code = code;

            data.plan.features = [{ code: `MENU-SUCURSALES-${this.currency}`, quantity: numSucursales }];
          }

          delete data.passwordMatch;
          delete data.numSuc;

          data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          this.signUp(data)
            .then(() => {
              if (data.email) localStorage.setItem('email', data.email);
              // eslint-disable-next-line
              const path = data.plan.code.includes('GR_BUSINESS')
                ? 'Demo-Negocio-en-Crecimiento'
                : data.plan.code.includes('FRANCHISES')
                ? 'Demo-Franquicias-y-Cadenas'
                : 'Freemium';
              window.history.pushState(null, '', `${path}/thank-you`);
              this.success = true;

              // Clear inputs value
              this.form.forEach((s, i) => {
                s.inputs.forEach((n, j) => {
                  this.form[i].inputs[j].value = '';
                });
              });
            })
            .catch((err) => {
              if (err.error.code === 'EMAIL_IN_USE') {
                this.errorMessage = 'El correo ya esta en uso';
                this.step = 0;
              }
              if (err.error.code === 'PHONE_IN_USE') {
                this.errorMessage = 'El teléfono ya esta en uso';
                this.step = 1;
              }
              this.error = true;
            });
        }
      }
    },
    handleInput({ name, value, telPrefix, country }) {
      const i = this.form[this.step].inputs.findIndex((inp) => inp.name === name);
      this.form[this.step].inputs[i].value = value;

      if (name === 'phone') {
        this.form[this.step].inputs[i].telPrefix = telPrefix;
        this.form[this.step].inputs[i].country = country;
      }

      if (name === 'passwordMatch') {
        this.form[this.step].inputs[i].validation = !this.isPasswordMatch;
      }
    },
    checkInput({ name, valid }) {
      if (name) {
        const i = this.form[this.step].inputs.findIndex((inp) => inp.name === name);
        this.form[this.step].inputs[i].valid = valid;
      }
    },
    getValues() {
      const data = {};
      const inputs = this.form.map((f) => f.inputs);
      inputs
        .reduce((pre, cur) => pre.concat(cur))
        .forEach(({ name, value, telPrefix, country }) => {
          data[name] = name === 'email' ? value.toLowerCase() : value;
          if (name === 'phone') {
            data[name] = `+${telPrefix} ${value}`;
            data.country = country;
          }
        });

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-form {
  &-wrapper {
    height: calc(100% - 77px);
    align-content: center;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .md-form {
    height: 100vh;
  }
}
</style>
