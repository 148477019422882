<template>
  <div class="md-form bg-dark">
    <!-- Header -->
    <nav class="navbar navbar-dark bg-dark py-1 py-lg-3">
      <div class="container">
        <a class="navbar-brand" href="/"><Logo /></a>
      </div>
    </nav>
    <!-- Content -->
    <div
      class="md-form-wrapper container row justify-content-lg-between row px-3 mx-0 py-5 m-md-auto">
      <FormInfo class="col-12 col-lg-6 mb-5" v-bind="info" />
      <FormInputs
        v-if="!success || error"
        class="col-12 col-lg-6"
        :step="step"
        :error="error"
        :errMsg="errorMessage"
        :loading="loading"
        :nextStep="nextStep"
        :activeStep="activeStep"
        @form="handleForm">
        <template v-slot:inputs>
          <Input
            class="mb-2"
            v-for="input in activeStep.inputs"
            :key="input.name"
            v-bind="input"
            :validated="validated"
            @valid="checkInput"
            @input="handleInput" />
        </template>
      </FormInputs>
      <FormSuccess v-else class="col-12 col-lg-6" v-bind="finish">
        <template v-slot:icon>
          <span class="icon-circle-check text-primary" style="font-size: 14rem"></span>
        </template>
        <template v-slot:extra>
          <Button class="mx-auto" style="width: fit-content" :route="'/'">
            Volver al Inicio
          </Button>
        </template>
      </FormSuccess>
    </div>
    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P4Z6V3X"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Logo from '@/components/common/Logo.vue';
import Input from '@/components/common/Input.vue';
import Button from '@/components/common/Button.vue';
import FormInfo from '@/components/form/FormInfo.vue';
import FormInputs from '@/components/form/FormInputs.vue';
import FormSuccess from '@/components/form/FormSuccess.vue';

import input from '@/store/inputs';

export default {
  name: 'FormMultimarca',
  components: {
    FormSuccess,
    FormInputs,
    FormInfo,
    Button,
    Input,
    Logo,
  },
  mounted() {
    const email = localStorage.getItem('email');
    this.success = !!email;
  },
  data() {
    return {
      step: 0,
      error: null,
      errorMessage: 'Algo ha salido mal, intenta de nuevo',
      success: false,
      validated: false,
      info: {
        title: 'Cotiza tu plan 👌',
        description:
          'Compartenos tu información. Un asesor se pondrá en contacto contigo para analizar el mejor plan a tu medida.',
        img: 'img-form-menu.webp',
      },
      form: [
        {
          title: 'Información de la empresa',
          inputs: [
            input.companyName,
            input.businessKind,
            input.numBrands,
            input.numSucursales,
            input.website,
          ],
        },
        {
          title: 'Información de contacto',
          inputs: [input.firstName, input.lastName, input.email, input.phone, input.puesto],
        },
      ],
      finish: {
        title: '¡Super!',
        description: 'Se ha enviado tu respuesta, pronto te contactaremos',
      },
    };
  },
  computed: {
    ...mapState({ forms: (state) => state.form }),
    loading() {
      return this.form.loading;
    },
    activeStep() {
      return this.form[this.step];
    },
    nextStep() {
      return this.form[this.step + 1];
    },
  },
  methods: {
    ...mapActions('forms', ['sendForm']),
    handleForm() {
      const stepErr = this.form[this.step].inputs.find((i) => !i.valid);
      this.validated = true;
      this.error = false;

      this.$log(this.form);
      if (!stepErr) {
        if (this.step < this.form.length - 1) {
          this.validated = false;
          this.step += 1;
        } else {
          const data = this.getValues();
          this.$log('Saving form', data);
          this.sendForm({ title: 'Mindfood multimarca', form: data }).then(() => {
            const email = data.find((i) => i.question === 'Correo electrónico');
            if (email.answer) localStorage.setItem('email', email.answer);

            window.history.pushState(null, '', `Cotizacion-Multimarca/thank-you`);

            this.success = true;

            // Clear inputs value
            this.form.forEach((s, i) => {
              s.inputs.forEach((n, j) => {
                this.form[i].inputs[j].value = '';
              });
            });
          });
        }
      }
    },
    handleInput({ value, name, telPrefix, country }) {
      const i = this.form[this.step].inputs.findIndex((inp) => inp.name === name);
      this.form[this.step].inputs[i].value = value;

      if (name === 'phone') {
        this.form[this.step].inputs[i].telPrefix = telPrefix;
        this.form[this.step].inputs[i].country = country;
      }
    },
    checkInput({ valid, name }) {
      if (name) {
        const i = this.form[this.step].inputs.findIndex((inp) => inp.name === name);
        this.form[this.step].inputs[i].valid = valid;
      }
    },
    getValues() {
      const data = [];
      const inputs = this.form.map((f) => f.inputs);
      inputs
        .reduce((pre, cur) => pre.concat(cur))
        .forEach(({ label, name, value, placeholder, child, telPrefix, country }) => {
          const chVal = child && child.value ? child.value : '';

          let phone;
          if (name === 'phone') {
            phone = `+${telPrefix} ${value}`;
            data.push({ question: 'País', answer: country });
          }

          data.push({ question: label || placeholder, answer: chVal || phone || value });
        });

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-form {
  &-wrapper {
    height: calc(100% - 77px);
    align-content: center;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .md-form {
    height: 100vh;
  }
}
</style>
