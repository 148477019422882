/** Styles */
import 'normalize.css';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.min';
import './assets/icons/font/style.css';
import './assets/fonts/style.css';

/** Vue */
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import utils from './utils';
import { http, log, regex, mexTimezone } from './config'; // axios instance

Vue.config.productionTip = false;
Vue.prototype.$regex = regex;
Vue.prototype.$http = http;
Vue.prototype.$log = log;
Vue.prototype.$mexTimezone = mexTimezone;
Vue.prototype.$priceFormat = utils.priceFormat;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
