<template>
  <div class="md-form-success d-flex align-items-center bg-light p-5">
    <div class="text-center">
      <slot name="icon"></slot>
      <p class="fs-2 fw-bold mb-5">{{ title }}</p>
      <p class="fs-4 mb-3" v-html="description"></p>
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormSuccess',
  data() {
    return {};
  },
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.md-form {
  &-success {
    // height: fit-content;
    max-width: 500px;
    border-radius: 20px;
  }
}
</style>
