<template>
  <div class="md-trial text-md-center m-lg-auto" :id="trialPeriod.id">
    <div class="md-trial-cta bg-dark px-3 py-5 text-light">
      <h2 class="mb-1 mt-lg-4">{{ trialPeriod.title }}</h2>
      <p class="pb-4 m-md-auto">{{ trialPeriod.description }}</p>
      <Button class="md-trial-cta-btn m-auto mb-lg-4" :href="'#pricing'">{{
        trialPeriod.cta
      }}</Button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/common/Button.vue';

export default {
  name: 'TrialPeriodCta',
  components: {
    Button,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    trialPeriod() {
      return this.sections.filter((s) => s.id === 'trialPeriod')[0];
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .md-trial {
    &-cta p {
      max-width: 760px;
    }
  }
}
@media (min-width: 992px) {
  .md-trial {
    max-width: 1500px;
    width: 85%;
    &-cta {
      border-radius: 40px;
      &-btn {
        width: fit-content;
      }
    }
  }
}
</style>
