<template>
  <section class="md-features m-lg-auto" :id="features.id">
    <div class="md-features-content px-3">
      <article
        class="md-features-item d-lg-flex justify-content-between align-items-center"
        :class="{ 'flex-row-reverse': (i + 1) % 2 }"
        v-for="(f, i) in features.features"
        :key="i">
        <div class="md-features-img text-center mb-4">
          <picture>
            <source media="(min-width:758px)" :srcset="require('@/assets/images/' + f.img)" />
            <img :src="require('@/assets/images/mobile/' + f.img)" :alt="f.title" />
          </picture>
          <!-- <img class="mb-4" :src="require('@/' + f.img)" :alt="f.title" /> -->
        </div>
        <div class="md-features-info m-md-auto m-lg-0">
          <h2 class="md-features-title mb-3">{{ f.title }}</h2>
          <p class="md-features-description mb-4">{{ f.description }}</p>
          <div v-if="f.options" class="md-features-options">
            <p v-if="f.options.title" class="fw-bolder mb-2">{{ f.options.title }}</p>
            <Checklist
              class="md-features-check-list mb-3 mb-lg-4"
              :size="'md'"
              :check="f.options.check"
              :weight="f.options.fw"
              :fitList="true"
              :list="f.options.list" />
          </div>
          <p v-if="f.description2" class="md-features-description mb-4">{{ f.description2 }}</p>
          <p v-if="f.tip"><strong>PRO TIP:</strong>{{ f.tip }}</p>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Checklist from '@/components/common/Checklist.vue';

export default {
  name: 'Features',
  components: {
    Checklist,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['sections']),
    features() {
      return this.sections.filter((s) => s.id === 'features')[0];
    },
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-features {
  padding-bottom: 100px;
  &-item {
    padding-top: 100px;
  }
  &-title {
    max-width: 300px;
  }
  &-check-list {
    max-width: 430px;
  }
}

@media (min-width: 768px) {
  .md-features {
    &-info {
      max-width: 602px;
    }
    &-title {
      max-width: 550px;
    }
  }
}

@media (min-width: 992px) {
  .md-features {
    max-width: 1000px;
    &-info {
      max-width: 430px;
    }
    &-img {
      max-width: 45%;
    }
  }
}
@media (min-width: 1400px) {
  .md-features {
    max-width: 1300px;
    &-info {
      max-width: 602px;
    }
  }
}
</style>
